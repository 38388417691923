import React from "react"
import Container from "../../container"
import ctl from "@netlify/classnames-template-literals"

import StepsList from "./list"
import StepsLine from "./line"
import TradingStepsTitle from "./title"
import StepsButtonGroup from "./button-group"

const TradingSteps = ({ long }) => {
  const containerStyle = ctl(
    `md:mt-24 mt-12 flex md:justify-between justify-center md-max:w-[300px] md-max:px-0 md-max:mx-auto flex-wrap sm:w-10/12 `
  )

  return (
    <Container size="medium" className={containerStyle}>
      <TradingStepsTitle long={long}/>
      <StepsLine />
      <StepsList />
      <StepsButtonGroup isMobile />
    </Container>
  )
}

export default TradingSteps

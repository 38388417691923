import React from "react"
import PropTypes from "prop-types"
import ctl from "@netlify/classnames-template-literals"

import Button from "../../button"

const StepsButtonGroup = ({ isMobile, className }) => {
  const buttonGroupStyle = ctl(
    `button-group md:text-left text-center ${className}  ${
      isMobile ? "md:hidden flex flex-col gap-y-[20px]" : "md:flex hidden mt-[50px]"
    }`
  )

  const buttonStyle = 'md-max:!w-full md-max:text-sm md:!w-46% md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px]'

  return (
    <div className={buttonGroupStyle}>
      <Button
        label="Start Trading"
        url="https://clients.eagleglobalmarkets.com/#/"
        isExternal
        inGroup
        hasMargin={false}
        className={buttonStyle}
      />
      <Button
        url="https://mt4.egmarkets.trade/"
        label="Try Demo Trading"
        type="secondary"
        isExternal
        inGroup
        hasMargin={false}
        className={buttonStyle}
      />
    </div>
  )
}

StepsButtonGroup.propTypes = {
  isMobile: PropTypes.bool
}

StepsButtonGroup.defaultProps = {
  isMobile: false
}

export default StepsButtonGroup

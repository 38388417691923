import React from "react"
import ctl from "@netlify/classnames-template-literals"
import signUpIcon from "icons/trading/sign-up.svg"
import startTradingIcon from "icons/trading/start-trading.svg"
import verifyIcon from "icons/trading/verify.png"

const steps = [
  {
    title: "Sign Up",
    description: (
      <>
        Open a live account <br/> in a couple of minutes
      </>
    ),
    icon: signUpIcon,
  },
  {
    title: "Verify Your Id",
    description: (
      <span>
        Upload a copy of your <br/> government-issued ID.
      </span>
    ),
    icon: verifyIcon,
  },
  {
    title: "Start Trading",
    description: (
      <span>
        Access 1000+ assets on <br/> our Naira or Meta Trader 4 platform.
      </span>
    ),
    icon: startTradingIcon,
  },
]
const Step = ({ item, isLast }) => {
  const { title, description, icon } = item

  const stepStyle = ctl(`flex md:mx-0 mx-auto ${!isLast && 'mb-[62px]'} `)
  const stepIconStyle = ctl(`block w-[51px] mr-8`)
  const stepDescriptionStyle = ctl(`mt-2 text-sm w-[168px] md:w-48`)

  return (
    <li className={stepStyle}>
      <span className={stepIconStyle}>
        <img src={icon} alt="" />
      </span>

      <div>
        <h4 className='font-extrabold'>{title}</h4>
        <p className={stepDescriptionStyle}>{description}</p>
      </div>
    </li>
  )
}

const StepsList = () => {
  const stepsListStyle = ctl(`md:mt-0 mt-12 md:flex-shrink-0 flex-shrink`)

  return (
    <section className={stepsListStyle}>
      <ol>
        {steps.map((step, index) => (
          <Step item={step} key={index} isLast={index === steps.length - 1}/>
        ))}
      </ol>
    </section>
  )
}

export default StepsList

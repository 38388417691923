import React from "react"
import ctl from "@netlify/classnames-template-literals"
import badgeIcon from "icons/trading/badge.svg"
import badgeIconM from "icons/trading/badge-m.svg"
import crossIcon from "icons/trading/cross.svg"

import StepsButtonGroup from "./button-group"

const TradingStepsTitle = ({ long = false }) => {
  const headingStyle = ctl(
    `font-monument md:text-[45px] text-[38px] md:text-left text-center font-bold leading-[1.33] relative`
  )
  const textWrapperStyle = ctl(`md:w-auto w-full md:pt-[32px]`)

  const badgeStyle = ctl(
    `absolute md:left-[124px] left-[20%] md:top-[-20px] 
    top-[-20px] md:w-[68px] z-[-1] pointer-events-none md-max:hidden`
  )

  const badgeStyleM = ctl(
    `absolute left-[15%] md:top-[-20px] 
    top-[-20px] md:w-[68px] z-[-1] pointer-events-none md:hidden`
  )

  const crossStyle = ctl(`absolute md:w-[51px] bottom-0 ${long ? 'md:left-[270px]' : 'md:left-[200px]'} left-[220px] z-[-1]`)

  return (
    <section className={textWrapperStyle}>
      <h2 className={headingStyle}>
        {
          long ? (
            <>
              Start <br />
              Trading The <br/>
              Financial Markets
              <br/> in 3 steps
            </>
          ) : (
            <>
              Start <br />
              Trading in
              <br /> 3 steps
            </>
          )
        }
        <img src={badgeIcon} alt="" className={badgeStyle} />
        <img src={badgeIconM} alt="" className={badgeStyleM} />
        <img src={crossIcon} alt="" className={crossStyle} />
      </h2>

      <StepsButtonGroup className='w-[343px]'/>
    </section>
  )
}

export default TradingStepsTitle

import React from "react"
import ctl from "@netlify/classnames-template-literals"

const StepsLine = () => {
  const lineStyle = ctl(
    `w-[1px] bg-blue relative h-[313px] md:mr-0 mr-[30px] flex-shrink  md:mt-0 mt-14`
  )
  const dotStyle = ctl(
    `bg-blue w-[10px] h-[10px] block rounded-full absolute left-[-5px] even:top-[50%] transform even:translate-y-[-50%] last:bottom-[0] `
  )
  return (
    <div className={lineStyle}>
      <span className={dotStyle}></span>
      <span className={dotStyle}></span>
      <span className={dotStyle}></span>
    </div>
  )
}

StepsLine.propTypes = {}

export default StepsLine
